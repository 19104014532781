@font-face 
{
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face 
{
  font-family: 'RobotoBold';
  src: url('./fonts/Roboto-Bold.ttf');
  font-display: swap;
}
